import { uniqueId } from "@blueprintjs/core/lib/esm/common/utils";

export const customOswaltMemeTxt = {
  // id:
  //   Math.random().toString(36).substring(2, 15) +
  //   Math.random().toString(36).substring(2, 15),
  type: "text",
  width: 200,
  height: 93,
  rotation: 0,
  animations: [],
  blurEnabled: false,
  blurRadius: 10,
  brightnessEnabled: false,
  brightness: 0,
  sepiaEnabled: false,
  grayscaleEnabled: false,
  shadowEnabled: false,
  shadowBlur: 5,
  shadowColor: "black",
  shadowOpacity: 1,
  draggable: true,
  resizable: true,
  contentEditable: true,
  styleEditable: true,
  text: "Poster is Fun!",
  placeholder: "",
  fontSize: 76,
  fontFamily: "Oswald",
  fontStyle: "normal",
  fontWeight: "normal",
  textDecoration: "",
  fill: "rgba(220,220,220,1)",
  align: "center",
  verticalAlign: "top",
  strokeWidth: 4,
  stroke: "black",
  lineHeight: 1.2,
  backgroundEnabled: false,
  backgroundColor: "#7ED321",
  backgroundOpacity: 1,
  backgroundCornerRadius: 0.5,
  backgroundPadding: 0.5,
};
