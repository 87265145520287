import React from 'react';

const EditIconMyFIles = ({ width = 32, height = 32, stroke = "#1C274C", strokeWidth = 1.5 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.5" d="M14.8612 14.6967C15.3679 13.7877 15.6212 13.3333 16 13.3333C16.3788 13.3333 16.6321 13.7877 17.1388 14.6967L17.2699 14.9319C17.4137 15.1901 17.4857 15.3192 17.598 15.4044C17.7103 15.4896 17.85 15.5213 18.1296 15.5845L18.3841 15.6421C19.368 15.8648 19.86 15.976 19.9771 16.3524C20.0941 16.7288 19.7587 17.1209 19.088 17.9053L18.9144 18.1083C18.7239 18.3311 18.6285 18.4425 18.5856 18.5804C18.5428 18.7183 18.5572 18.8669 18.586 19.1644L18.6123 19.4351C18.7136 20.4816 18.7644 21.0048 18.458 21.2375C18.1515 21.47 17.6909 21.258 16.7697 20.8339L16.5315 20.7241C16.2696 20.6036 16.1388 20.5433 16 20.5433C15.8612 20.5433 15.7304 20.6036 15.4685 20.7241L15.2303 20.8339C14.3091 21.258 13.8485 21.47 13.542 21.2375C13.2356 21.0048 13.2863 20.4816 13.3877 19.4351L13.414 19.1644C13.4428 18.8669 13.4572 18.7183 13.4144 18.5804C13.3715 18.4425 13.2762 18.3311 13.0856 18.1083L12.912 17.9053C12.2413 17.1209 11.9059 16.7288 12.023 16.3524C12.14 15.976 12.6319 15.8648 13.6159 15.6421L13.8704 15.5845C14.15 15.5213 14.2897 15.4896 14.402 15.4044C14.5143 15.3192 14.5863 15.1901 14.7301 14.9319L14.8612 14.6967Z" stroke={stroke} strokeWidth={strokeWidth}/>
      <path d="M2.66675 9.26632C2.66675 8.08962 2.66675 7.50126 2.75921 7.01118C3.16627 4.85374 4.85383 3.16618 7.01127 2.75912C7.50135 2.66666 8.08971 2.66666 9.26641 2.66666C9.78197 2.66666 10.0398 2.66666 10.2875 2.68983C11.3556 2.78971 12.3688 3.20938 13.1947 3.894C13.3862 4.0528 13.5685 4.2351 13.933 4.59966L14.6667 5.33332C15.7545 6.42103 16.2983 6.96488 16.9495 7.32723C17.3073 7.52627 17.6869 7.68346 18.0806 7.7957C18.7973 7.99999 19.5663 7.99999 21.1046 7.99999H21.6029C25.1127 7.99999 26.8675 7.99999 28.0083 9.02594C28.1133 9.12031 28.2131 9.22018 28.3074 9.3251C29.3334 10.4658 29.3334 12.2207 29.3334 15.7305V18.6667C29.3334 23.6949 29.3334 26.2092 27.7713 27.7712C26.2093 29.3333 23.695 29.3333 18.6667 29.3333H13.3334C8.30509 29.3333 5.79095 29.3333 4.22884 27.7712C2.66675 26.2092 2.66675 23.6949 2.66675 18.6667V9.26632Z" stroke={stroke} strokeWidth={strokeWidth}/>
    </svg>
  );
};

export default EditIconMyFIles;