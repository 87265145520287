import React, { useState } from "react";

const EditIconAI = ({
  width = 32,
  height = 32,
  stroke = "#1C274C",
  strokeWidth = 1.5,
  needAnimation = false,
}) => {
  const [animation, setAnimation] = useState(needAnimation);
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.86162 8.34969C2.73558 7.22366 2.73558 5.39799 3.86162 4.27196C4.98765 3.14592 6.81332 3.14592 7.93935 4.27196L20.1726 16.5051C21.2986 17.6312 21.2986 19.4568 20.1726 20.5829C19.0465 21.7089 17.2209 21.7089 16.0948 20.5829L3.86162 8.34969Z"
        stroke="#1C274C"
        stroke-width="1.5"
      />
      <path
        opacity="0.5"
        d="M6.01709 10.4274L10.0171 6.42743"
        stroke="#1C274C"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M16.1171 2.73462C16.2781 2.32503 16.8556 2.32503 17.0165 2.73462L17.4469 3.8299C17.4961 3.95495 17.5947 4.05394 17.7193 4.10326L18.8105 4.53523C19.2186 4.69677 19.2186 5.27643 18.8105 5.43797L17.7193 5.86995C17.5947 5.91927 17.4961 6.01825 17.4469 6.1433L17.0166 7.23858C16.8556 7.64817 16.2781 7.64817 16.1171 7.23859L15.6868 6.1433C15.6376 6.01825 15.539 5.91927 15.4144 5.86995L14.3232 5.43797C13.9151 5.27643 13.9151 4.69677 14.3232 4.53523L15.4144 4.10326C15.539 4.05394 15.6376 3.95495 15.6868 3.8299L16.1171 2.73462Z"
        stroke="#1C274C"
      />
      <path
        opacity="0.5"
        d="M19.9843 9.55688C20.1452 9.1473 20.7228 9.1473 20.8837 9.55688L21.0406 9.95623C21.0898 10.0813 21.1884 10.1803 21.313 10.2296L21.7108 10.3871C22.1189 10.5486 22.1189 11.1283 21.7108 11.2898L21.313 11.4473C21.1884 11.4966 21.0898 11.5956 21.0406 11.7206L20.8837 12.12C20.7228 12.5296 20.1452 12.5296 19.9843 12.12L19.8274 11.7206C19.7782 11.5956 19.6796 11.4966 19.555 11.4473L19.1571 11.2898C18.7491 11.1283 18.7491 10.5486 19.1571 10.3871L19.555 10.2296C19.6796 10.1803 19.7782 10.0813 19.8274 9.95623L19.9843 9.55688Z"
        stroke="#1C274C"
      />
      <path
        opacity="0.5"
        d="M5.15029 15.7346C5.31123 15.325 5.88876 15.325 6.0497 15.7346L6.20662 16.1339C6.25576 16.259 6.35438 16.358 6.47897 16.4073L6.87684 16.5648C7.28492 16.7263 7.28492 17.306 6.87684 17.4675L6.47897 17.625C6.35438 17.6743 6.25576 17.7733 6.20662 17.8984L6.0497 18.2977C5.88876 18.7073 5.31123 18.7073 5.15029 18.2977L4.99337 17.8984C4.94423 17.7733 4.84561 17.6743 4.72102 17.625L4.32315 17.4675C3.91507 17.306 3.91507 16.7263 4.32315 16.5648L4.72102 16.4073C4.84561 16.358 4.94423 16.259 4.99337 16.1339L5.15029 15.7346Z"
        stroke="#1C274C"
      />
    </svg>
  );
};

export default EditIconAI;
